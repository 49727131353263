import React from 'react';

function LinkedInIcon() {
  return (
    <svg
      className='skill__icon'
      xmlns='http://www.w3.org/2000/svg'
      x='0'
      y='0'
      enableBackground='new 0 0 64 64'
      version='1.1'
      viewBox='0 0 64 64'
      xmlSpace='preserve'
    >
      <path d='M14.5 62.8H5.6c-3.1 0-5.6-2.5-5.6-5.5V27.9c0-3 2.5-5.5 5.6-5.5h8.9c3 0 5.6 2.5 5.6 5.5v29.4c.1 3-2.5 5.5-5.6 5.5zM5.6 27.4c-.3 0-.6.2-.6.5v29.4c0 .2.2.5.6.5h8.9c.3 0 .6-.2.6-.5V27.9c0-.2-.3-.5-.6-.5H5.6zM58.4 62.8h-8.9c-3.1 0-5.6-2.5-5.6-5.5V45.2c0-.6-.5-1-1.1-1-.6 0-1.1.5-1.1 1v12c0 3-2.5 5.5-5.6 5.5h-8.9c-3.1 0-5.6-2.5-5.6-5.5V27.9c0-3 2.5-5.5 5.6-5.5H36c1.9 0 3.5.9 4.5 2.3.4-.2.8-.4 1.1-.6 3.1-1.4 6.6-1.9 9.8-1.5 7 .8 12.3 6.7 12.5 13.6v21.2c.1 2.9-2.4 5.4-5.5 5.4zM42.7 39.2c3.4 0 6.1 2.7 6.1 6v12.1c0 .2.2.5.6.5h8.9c.3 0 .6-.2.6-.5V36.2c-.1-4.3-3.7-8.1-8.1-8.6-2.3-.3-4.9.1-7.2 1.1-1.1.5-2.2 1.1-3.2 1.8l-3.9 2.8v-5.4c0-.2-.2-.5-.6-.5H27c-.3 0-.6.2-.6.5v29.4c0 .2.2.5.6.5h9c.3 0 .6-.2.6-.5v-12c0-3.4 2.7-6.1 6.1-6.1zM10.7 21.9C4.9 21.9.1 17.3.1 11.5S4.8 1.2 10.7 1.2s10.6 4.6 10.6 10.4-4.8 10.3-10.6 10.3zm0-15.7c-3.1 0-5.6 2.4-5.6 5.4S7.6 17 10.7 17s5.6-2.4 5.6-5.4c0-3.1-2.5-5.4-5.6-5.4z'></path>
    </svg>
  );
}

export default LinkedInIcon;
