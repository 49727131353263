import React from 'react';

function SvelteIcon() {
  return (
    <svg
      className='skill__icon'
      xmlns='http://www.w3.org/2000/svg'
      x='0'
      y='0'
      enableBackground='new 0 0 32 32'
      version='1.1'
      viewBox='0 0 32 32'
      xmlSpace='preserve'
    >
      <path d='M27.6 4.2c-3-4.2-8.7-5.4-13.1-2.8L7 6.2c-2 1.3-3.4 3.4-3.9 5.7-.4 2 0 4 .9 5.8-.6 1-1.1 2.1-1.3 3.2-.4 2.4.1 4.9 1.5 6.9 2.9 4.2 8.6 5.5 13.1 2.8l7.5-4.8c2-1.3 3.4-3.4 3.8-5.7.4-2 0-4-.9-5.8 2.1-3.1 2-7.1-.1-10.1zm-13.8 24c-2.4.6-4.9-.3-6.3-2.4-.9-1.2-1.2-2.7-.9-4.1V21l.1-.4.4.3C8 21.6 9 22 10 22.4l.3.1v.3c0 .4.1.8.3 1.1.4.6 1.2.9 1.9.7.2-.1.3-.1.5-.2l7.4-4.7c.4-.2.6-.6.7-1 .1-.4 0-.9-.3-1.3-.4-.6-1.2-.9-1.9-.7-.2.1-.3.1-.5.2l-2.8 1.8c-2.7 1.6-6.1.8-7.9-1.7-.9-1.2-1.2-2.7-.9-4.1.3-1.7 1.2-2.9 2.4-3.7l7.4-4.7c.5-.3 1-.5 1.5-.7 2.4-.6 4.9.3 6.3 2.3 1 1.4 1.3 3.2.8 4.8l-.1.4-.4-.3c-.9-.7-1.9-1.1-2.9-1.5l-.3-.1v-.3c0-.4-.1-.8-.3-1.1-.4-.6-1.2-.9-1.9-.7-.2.1-.3.1-.5.2l-7.5 4.8c-.4.2-.6.6-.7 1-.1.4 0 .9.3 1.2.4.6 1.2.9 1.9.7.2-.1.3-.1.5-.2l2.8-1.8c.5-.3 1-.5 1.5-.7 2.4-.6 4.9.3 6.3 2.4.9 1.2 1.2 2.7 1 4.1-.2 1.4-1.1 2.7-2.3 3.5l-7.4 4.7c-.4.6-.9.8-1.4 1z'></path>
    </svg>
  );
}

export default SvelteIcon;
