import React from 'react';

function GitHubIcon() {
  return (
    <svg
      className='skill__icon'
      xmlns='http://www.w3.org/2000/svg'
      x='0'
      y='0'
      enableBackground='new 0 0 64 64'
      version='1.1'
      viewBox='0 0 64 64'
      xmlSpace='preserve'
    >
      <path d='M64 31C63.3 14.5 49.9 1.1 33.4.5 24.6.1 16.2 3.3 9.8 9.4 3.5 15.5 0 23.7 0 32.5c0 14.4 9.7 27.1 23.5 30.8.4.1.8.2 1.2.2 1 0 2-.3 2.8-1 1.1-.9 1.8-2.2 1.8-3.6v-3.3c0-.9.1-4.9.1-5.7.1-.5.2-.9.5-1.2.7-.9.8-2.1.3-3.1-.5-.9-1.3-1.5-2.3-1.6-.7-.1-1.4-.2-1.9-.4-.8-.2-1.6-.6-2.4-1-.6-.3-1.2-.8-1.7-1.4-.4-.5-.8-1.3-1.1-2.3-.4-1.2-.6-2.5-.6-3.9 0-1.8.6-3.3 1.7-4.5l1.1-1.2-.6-1.5c-.2-.5-.4-1.2-.3-2.4 1.4.5 1.8.8 1.8.8l.1.1c.4.2.7.5 1 .6l1.2.7 1-.3c1.7-.5 3.4-.7 5.2-.7 1.8 0 3.5.2 5.2.7l1 .3 2.1-1.3c.7-.4 1.4-.8 2.2-1.1.1.9 0 1.7-.3 2.5l-.6 1.5 1.1 1.2c1.1 1.2 1.7 2.7 1.7 4.5 0 1.5-.2 2.7-.5 3.8-.4 1.2-.8 1.9-1.2 2.4-.4.5-1 1-1.8 1.5-.9.5-1.6.7-2.4 1-.5.2-1.3.3-2 .4h-.1c-1 .2-1.8.9-2.2 1.8-.4.9-.3 1.9.3 2.8.4.5.6 1.3.6 2.3v7.9c0 1.4.7 2.8 1.8 3.6 1.1.8 2.6 1.1 4 .7C55.5 58.9 64.7 45.6 64 31zM40.7 58.2V51c0-.9-.1-1.7-.3-2.4 1.1-.3 2.2-.7 3.4-1.4l.1-.1c1.3-.8 2.3-1.6 3.1-2.6 1-1.2 1.6-2.6 2.1-4 .5-1.6.8-3.3.8-5.4 0-2.6-.7-4.8-2.1-6.8.4-2.1.2-4.2-.7-6.5l-.4-1.1-1.1-.4c-1.1-.4-2.5-.3-4.2.4-1.1.4-2.1.9-3.2 1.5l-.2.2c-3.5-.8-7.3-.8-10.9 0-.1-.1-.2-.1-.3-.2-.6-.4-1.5-.9-2.9-1.4-1.7-.8-3.2-.9-4.4-.5l-1.1.4-.4 1.1c-.9 2.4-1.1 4.5-.6 6.5-1.4 1.9-2.1 4.2-2.1 6.8 0 1.9.2 3.6.8 5.3.5 1.7 1.2 3.1 2 4.1 1 1.1 2 2 3.2 2.6 1.1.6 2.2 1.1 3.4 1.4-.1.3-.1.6-.2 1V52.7c-2.4-.8-4.4-1.8-6-3.5-1.1-1.2-2.3-2.4-2.9-2.8-1.3-.6-2.1.7-1.8 1.3.3.9 1.6 1.4 2.3 2.1.8.8.9 1.9 1.6 2.8 1 1.4 4.5 3.1 6.9 2.6v3.2C12.9 55.1 5 44.5 5 32.5c0-7.4 2.9-14.3 8.3-19.5C18.4 8.1 25 5.5 32.1 5.5h1.1C47.1 6 58.4 17.3 59 31.2c.6 12.1-6.9 23.1-18.3 27z'></path>
    </svg>
  );
}

export default GitHubIcon;
