import React from 'react';

function GitIcon() {
  return (
    <svg
      className='skill__icon'
      xmlns='http://www.w3.org/2000/svg'
      x='0'
      y='0'
      enableBackground='new 0 0 64 64'
      version='1.1'
      viewBox='0 0 64 64'
      xmlSpace='preserve'
    >
      <path d='M63.5 31L33 .4c-.5-.5-1.4-.5-2 0l-8.4 8.4 7.8 7.8c.6-.3 1.3-.4 2-.4 2.9 0 5.1 2.3 5.1 5.1 0 .7-.1 1.4-.4 2l6.2 6.2c.6-.3 1.3-.4 2-.4 2.9 0 5.1 2.3 5.1 5.1 0 2.9-2.3 5.1-5.1 5.1-2.9 0-5.1-2.3-5.1-5.1 0-.7.1-1.4.4-2L34.4 26h-.1v11.8c1.9.8 3.2 2.6 3.2 4.8 0 2.9-2.3 5.1-5.1 5.1-2.9 0-5.1-2.3-5.1-5.1 0-2.2 1.3-4 3.2-4.8V26.2c-1.9-.8-3.2-2.6-3.2-4.8 0-.7.1-1.4.4-2l-7.8-7.9L.4 31c-.5.5-.5 1.4 0 2L31 63.6c.5.5 1.4.5 2 0L63.6 33c.5-.5.5-1.5-.1-2z'></path>
    </svg>
  );
}

export default GitIcon;
