import React from 'react';

function NodeIcon() {
  return (
    <svg
      className='skill__icon'
      xmlns='http://www.w3.org/2000/svg'
      x='0'
      y='0'
      enableBackground='new 0 0 64 64'
      version='1.1'
      viewBox='0 0 64 64'
      xmlSpace='preserve'
    >
      <path d='M32 64c-.9 0-1.7-.2-2.5-.7l-7.8-4.6c-1.2-.7-.6-.9-.2-1 1.6-.5 1.9-.7 3.5-1.6.2-.1.4-.1.6.1l6 3.6c.2.1.5.1.7 0l23.5-13.5c.2-.1.4-.4.4-.6V18.4c0-.3-.1-.5-.4-.6L32.3 4.3c-.2-.1-.5-.1-.7 0L8.2 17.8c-.2.1-.4.4-.4.6v27.1c0 .3.1.5.4.6l6.4 3.7c3.5 1.7 5.6-.3 5.6-2.4V20.8c0-.4.3-.7.7-.7h3c.4 0 .7.3.7.7v26.7c0 4.6-2.5 7.3-6.9 7.3-1.4 0-2.4 0-5.4-1.5l-6.1-3.5c-1.5-.9-2.5-2.5-2.5-4.3V18.4c0-1.8.9-3.4 2.5-4.3L29.5.6C31-.2 33-.2 34.4.6l23.5 13.6c1.5.9 2.5 2.5 2.5 4.3v27.1c0 1.8-.9 3.4-2.5 4.3L34.5 63.3c-.8.5-1.6.7-2.5.7zm18.9-26.7c0-5.1-3.4-6.4-10.6-7.4-7.3-1-8-1.5-8-3.2 0-1.4.6-3.3 6-3.3 4.8 0 6.6 1 7.3 4.3.1.3.3.5.7.5h3c.2 0 .4-.1.5-.2.1-.1.2-.3.2-.5-.5-5.6-4.2-8.2-11.7-8.2-6.7 0-10.7 2.8-10.7 7.6 0 5.1 4 6.6 10.4 7.2 7.7.7 8.3 1.9 8.3 3.4 0 2.6-2.1 3.7-7 3.7-6.2 0-7.6-1.6-8-4.6-.1-.3-.3-.6-.7-.6h-3c-.4 0-.7.3-.7.7 0 3.9 2.1 8.7 12.4 8.7 7.4 0 11.6-3 11.6-8.1z'></path>
    </svg>
  );
}

export default NodeIcon;
