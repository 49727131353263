import React from 'react';

function CSSIcon() {
  return (
    <svg
      className='skill__icon'
      xmlns='http://www.w3.org/2000/svg'
      x='0'
      y='0'
      enableBackground='new 0 0 64 64'
      version='1.1'
      viewBox='0 0 64 64'
      xmlSpace='preserve'
    >
      <path d='M63 1.3C62.4.6 61.4.2 60.5.2h-57C2.5.2 1.6.6 1 1.3.3 2 0 2.9.1 3.9l5.1 51.4c.1 1.3 1.1 2.5 2.5 2.9l22.6 5.5c.5.1 1 .2 1.5.2s1.1-.1 1.6-.2L56 58.2c1.3-.3 2.3-1.5 2.4-2.8l5.4-51.5c.2-1-.1-1.9-.8-2.6zm-9.3 52.3l-21.4 5.2c-.3.1-.7.1-.9 0L10 53.6 5.3 5.2h53.5l-5.1 48.4z'></path>
      <path d='M11 13.5c0 1.4 1.1 2.5 2.5 2.5h34.6l-1.4 8H18.5c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5h27.3l-2.3 12.7L33 45.4l-10.5-3.8-1.4-6.6c-.3-1.4-1.6-2.2-2.9-1.9-1.4.3-2.2 1.6-1.9 3l1.6 8c.2.9.8 1.6 1.6 1.9l12.7 4.5c.3.1.6.1.8.1s.6 0 .8-.1L46.5 46c.8-.3 1.5-1 1.6-1.9L53 16.6c.3-1.4-.1-2.7-1-3.8l-.1-.1c-.9-1-2.2-1.5-3.6-1.5h-35c-1.2-.2-2.3.9-2.3 2.3z'></path>
    </svg>
  );
}

export default CSSIcon;
