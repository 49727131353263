import React from 'react';

function HTMLIcon() {
  return (
    <svg
      className='skill__icon'
      xmlns='http://www.w3.org/2000/svg'
      x='0'
      y='0'
      enableBackground='new 0 0 64 64'
      version='1.1'
      viewBox='0 0 64 64'
      xmlSpace='preserve'
    >
      <path d='M63.2 5.1c-.6-.7-1.6-1.1-2.6-1.2H3.4c-1 0-1.9.4-2.6 1.1-.6.8-.9 1.7-.8 2.6l5.1 44.8c.1 1.3 1.1 2.5 2.5 2.8L30.3 60c.5.1 1 .1 1.5.1s1 0 1.5-.1L56 55.2c1.2-.3 2.4-1.3 2.5-2.7L64 7.6c.1-.9-.2-1.8-.8-2.5zm-9.4 45.4L32.4 55c-.3.1-.8.1-1 0L10 50.5 5.2 8.9h53.6l-5 41.6z'></path>
      <path d='M13.2 14.5l-.1.1c-1 1.1-1.3 2.4-1.1 3.7l1.6 7.6c.5 2.3 2.6 3.9 5.1 3.9h24.8l-2.1 8.9h-.1l-9.8 3.1h-.3l-9.8-3.1h-.1l-.9-4.6c-.3-1.4-1.6-2.2-3-1.9-1.4.3-2.2 1.6-1.9 3l1 4.7c.4 1.7 1.7 3.1 3.4 3.7l9.8 3.1c.5.2 1.1.3 1.7.3s1.1-.1 1.7-.3l9.8-3.1c1.7-.5 3-1.9 3.4-3.5l2.2-9.4c.3-1.4 0-2.8-.9-3.9-1-1.2-2.5-2-4.2-2H18.5l-1.3-6.6h31c1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.5-2.5H16.6c-1.3 0-2.5.5-3.4 1.3z'></path>
    </svg>
  );
}

export default HTMLIcon;
